import './index.css';
import tomImage from './assets/tom1.webp'

function App() {
  return (
    <div className='flex flex-grid grid-cols-3 w-full h-full gap-x-4 md:gap-x-10'>
      <div className='flex flex-col col-span-2 w-full mt-4 ml-4 md:mt-20 md:ml-20'>
        <span className='text-base font-bold md:text-3xl text-white'>
          Hey there, I'm Tom 👋
        </span>
        <br/>
        <span className='text-sm md:font-semibold md:text-xl text-white'>
          I'm a developer & dad living in Durham, NC, USA. 
          I'm a founder at a company called {' '}
          <a className='text-yellow-400 md:font-semibold underline' href="https://centaurlabs.com">Centaur Labs</a>.
          <br/><br/>
          You can connect with me {' '}
          <a className='text-yellow-400 md:font-semibold underline' href="https://www.linkedin.com/in/tomgellatly/">here</a>.
        </span>
        <br/>
        <hr/>
      </div>
      <div className='flex flex-col w-full col-span-1 mt-4 mr-4 md:mt-20 md:mr-20'>
        <img className='rounded-full' src={tomImage} />
      </div>
    </div>
  );
}

export default App;
